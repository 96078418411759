<template>
  <div class="bar-chart-container">
    <Doughnut :chartData="dataCollection" :options="chartOptions" />
  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js'
import Helper from '@/helpers/helper'

ChartJS.register(Title, Tooltip, Legend, ArcElement)

export default {
  name: 'DoughnutChart',
  components: {
    Doughnut
  },
  props: {
    filter: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      dataCollection: {
        labels: [],
        datasets: [
          {
            label: 'Taux d\'execution des projets par secteur en pourcentage (%)',
            backgroundColor: [
              '#f87979', '#a1c45a', '#4b77a9', '#e74c3c', '#2ecc71',
              '#3498db', '#f39c12', '#e67e22', '#1abc9c', '#9b59b6',
              '#34495e', '#16a085', '#27ae60', '#2980b9', '#c0392b',
              '#d35400', '#8e44ad', '#2c3e50', '#f1c40f', '#e74c3c',
              '#3498db', '#2ecc71', '#9b59b6', '#34495e', '#16a085',
              '#f39c12', '#e67e22', '#1abc9c', '#95a5a6', '#f1c40f'
            ],
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      fakeData: {},
    }
  },
  methods: {
    fetchData(filter, reload=false) {
      if (this.fakeData[filter]) {
        this.dataCollection.labels = this.fakeData[filter].labels
        this.dataCollection.datasets[0].data = this.fakeData[filter].values
      }

      if (reload == true) {
        fetch(Helper.route('statistics'), Helper.requestOptions())
        .then(response => {
            Helper.tokenChecker(response.status)
            return response.json()
        })
        .then(data => {
          console.log(data.taux.categories);
          this.fakeData = data.taux.categories
          const firstKey = Object.keys(this.fakeData)[0];
          this.dataCollection.labels = this.fakeData[firstKey].labels
          this.dataCollection.datasets[0].data = this.fakeData[firstKey].values
        }) 
      }
    }
  },
  watch: {
    filter: {
      immediate: true,
      handler(newFilter) {
        this.fetchData(newFilter);
      }
    }
  },
  mounted() {
    this.fetchData(this.filter, true);
  }
}
</script>

<style scoped>
.bar-chart-container {
  width: 50%;
  height: 50%; /* Ajustez cette hauteur en fonction de vos besoins */
}
</style>
