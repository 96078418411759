<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="filter-card">
      <label for="filter">Domaines:</label>
      <select id="filter" v-model="selectedFilter" @change="applyFilter" class="form-control">
        <option v-for="filter in filters" :key="filter.id" :value="filter.id">
          {{ filter.name }}
        </option>
      </select>
    </div>
    
    <div class="">
      <div class="row">
        <div class="col-md-6">
              <div class="card card-danger" style="height: 100%;">
                  <div class="card-header">
                      <h3 class="card-title">Nombre de projet par secteur</h3>
                  </div>
                  <div class="card-body">
                    <div class="chart py-4" >
                            <NumberBarChart :filter="selectedFilter" />
                    </div>
                  </div>
                      <!-- /.card-body -->
              </div>
        </div>

        <div class="col-md-6">
              <div class="card card-danger" style="height: 100%;">
                  <div class="card-header">
                      <h3 class="card-title">Montant total de financement par secteur</h3>
                  </div>
                  <div class="card-body">
                    <div class="chart py-4" >
                            <AmountBarChart :filter="selectedFilter" />
                    </div>
                  </div>
                      <!-- /.card-body -->
              </div>
        </div>

        <div class="col-md-12">
              <div class="card card-danger" style="height: 100%;">
                  <div class="card-header">
                      <h3 class="card-title">Taux d'execution des projets par secteur en pourcentage (%)</h3>
                  </div>
                  <div class="card-body">
                    <div class="chart py-4" >
                            <PieChart  :filter="selectedFilter" />
                    </div>
                  </div>
                      <!-- /.card-body -->
              </div>
        </div>
      </div>
      
      <!-- Ajoutez d'autres cartes ici si nécessaire -->
    </div>
    
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import NumberBarChart from "@/components/widgets/number-bar-chart";
import AmountBarChart from "@/components/widgets/amount-bar-chart";
import PieChart from "@/components/widgets/pie-chart";
import Helper from '@/helpers/helper';

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    NumberBarChart,
    AmountBarChart,
    PieChart
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Default",
          active: true,
        },
      ],
      selectedFilter: '1',
      filters: [],
    };
  },
  methods: {
    applyFilter() {
      // Logique pour appliquer le filtre sur les données des graphiques
      console.log('Filter applied:', this.selectedFilter);
      // Exemple d'appel à l'API avec le filtre sélectionné
      // this.fetchChartData(this.selectedFilter);
    },
    fetchFilters() {
      // API call to fetch filter options

      fetch(Helper.route('categories'), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log(data.data);
        this.filters = data.data
      })
    },
  },
  mounted() {
    setTimeout(() => {
      this.showModal = true;
    }, 1500);
    this.fetchFilters();
  },
};
</script>

<style scoped>
.filter-card {
  margin: 20px 0;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  /* flex: 1 1 calc(50% - 20px); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card-label {
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
}
</style>
