<template>
    <div class="bar-chart-container">
      <Bar :chartData="dataCollection" :options="chartOptions" />
    </div>
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js';
  import Helper from '@/helpers/helper'
  
  ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale)
  
  export default {
    name: 'AmountBarChart',
    components: {
      Bar
    },
    props: {
      filter: {
        type: String,
        default: 'all'
      }
    },
    data() {
      return {
        dataCollection: {
          labels: [],
          datasets: [
            {
              label: 'Montant total de financement en millions de franc',
              backgroundColor: '#4CAF50',
              data: []
            }
          ]
        },
        chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      

        // scales: {
        //   y: {
        //     beginAtZero: true,
        //     title: {
        //       display: true,
        //       text: 'Montant total de financement' // Y-axis label
        //     }
        //   }
        // }
      },
        fakeData: {},
      }
    },
    methods: {
      fetchData(filter, reload=false) {
        
        // Utiliser des données fictives pour le test
  
        // const fakeData = {
        //   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        //   values: [40, 20, 12, 39, 10, 40, 39]
        // }
        
        // this.dataCollection.labels = fakeData.labels
        // this.dataCollection.datasets[0].data = fakeData.values
  
        // this.fakeData = {
        //   all: {
        //     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        //     values: [40, 20, 12, 39, 10, 40, 39]
        //   },
        //   last7days: {
        //     labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
        //     values: [5, 10, 15, 20, 25, 30, 35]
        //   },
        //   last30days: {
        //     labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
        //     values: [50, 60, 70, 80]
        //   }
        // }


        
        if (this.fakeData[filter]) {
          this.dataCollection.labels = this.fakeData[filter].labels
          this.dataCollection.datasets[0].data = this.fakeData[filter].values
        }
  
        if(reload == true){
  
  
          fetch(Helper.route('statistics'), Helper.requestOptions())
          .then(response => {
              Helper.tokenChecker(response.status)
              return response.json()
          })
          .then(data => {
            console.log(data.amount.categories);
            this.fakeData = data.amount.categories
            const firstKey = Object.keys(this.fakeData)[0];
            this.dataCollection.labels = this.fakeData[firstKey].labels
            this.dataCollection.datasets[0].data = this.fakeData[firstKey].values
          }) 

        }
    
        // Décommenter pour utiliser des données réelles
        // fetch('https://api.example.com/data')
        //   .then(response => response.json())
        //   .then(data => {
        //     this.dataCollection.labels = data.labels
        //     this.dataCollection.datasets[0].data = data.values
        //   })
      }
    },
  
    watch: {
      filter: {
        immediate: true,
        handler(newFilter) {
          this.fetchData(newFilter);
        }
      }
    },
    mounted() {
      this.fetchData(this.filter, true);
    }
  
    // mounted() {
    //   this.fetchData()
    // }
  }
  </script>
  
  <style scoped>
  .bar-chart-container {
    width: 100%;
    height: 300px; /* Ajustez cette hauteur en fonction de vos besoins */
  }
  </style>
  